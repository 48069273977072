export const EXPERIMENT_URL_CUSTOMIZATION = 'specs.wixForum.URLCustomization';
export const EXPERIMENT_SHOW_RESOLVED_IN_TITLE = 'specs.wixForum.ShowResolvedInTitle';
export const EXPERIMENT_ENABLE_INSTALLATION_WIZARD = 'specs.wixForum.InstallationWizard';
export const EXPERIMENT_ENABLE_BM_SMART_POST_MODERATION = 'specs.wixForum.SmartPostModeration';
export const EXPERIMENT_USE_PRICING_PLANS_VIEWER_API = 'specs.wixForum.UsePricingPlansViewerApi';
export const EXPERIMENT_PERSIST_DRAFT_COMMENTS = 'specs.wixForum.persistDraftComment';
export const EXPERIMENT_GUEST_COMMENTING = 'specs.wixForum.guestCommenting';
export const EXPERIMENT_LOAD_MAX_COMMENTS_INITIALLY = 'specs.wixForum.loadMaxCommentsInitially';
export const EXPERIMENT_BM_GIZA =
  'specs.wosbm.migrate-communities-forum-business-manager-to-devcenter';
